import React, { useContext, useState } from "react"
import Layout from "@components/Layout/Layout";
import { Box, Typography } from "@material-ui/core"
import { FormGroup, FormControl, Form, FormFeedback } from "@components/Forms"
import { Label } from "@components/Forms"
import { makeComponentStyles } from "@components/mixins.js"
import Button from "@components/Buttons/Button.jsx"
import Link from "@components/Buttons/Link.jsx"
import SocialAuth from "@components/Auth/SocialAuth.jsx"
import required from "@rules/required.js"
import email from "@rules/email.js"
import AuthContext from '@src/auth/context';
import { useIntl, navigate } from "gatsby-plugin-react-intl";


const styles = ({ palette, breakpoints }) => ({
  form: {
    marginTop: "35px",
    width: "100%"
  },
  auth_button: {
    margin: "30px auto 15px auto",
    display: 'block'
  },
  footer__item: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  not_found_user_message: {
    color: palette.error.main,
    textAlign: "center"
  }
})

const initBody = {
  email: "",
  password: ""
}

function Login() {
  const {
    form,
    auth_button,
    footer__item,
    not_found_user_message
  } = makeComponentStyles(styles);

  const intl = useIntl();
  const [body, setBody] = useState(initBody);
  const [error, setError] = useState(false);
  const { auth } = useContext(AuthContext);

  const observeBody = (key, event) => {
    const newBody = {
      ...body,
      [key]: event.target.value.trim()
    }
    setBody(newBody);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    const { status, payload } = await auth.login(body);
    if (!status) setError(payload.message);
    else navigate('/users/account')
  }

  return (
    <Layout type="auth">
      <Typography variant="h2" component="h1">{intl.formatMessage({ id: "loginlabel" })}</Typography>
      <Form className={form} onSubmit={handleSubmit} validate>
        <FormGroup name={"email-input"}>
          <Label>Email</Label>
          <FormControl
            type="email"
            onChange={observeBody.bind(null, "email")}
            validations={[required, email]}
          />
          <FormFeedback />
        </FormGroup>
        <FormGroup name={"password-input"}>
          <Label>{intl.formatMessage({ id: "password" })}</Label>
          <FormControl
            type="password"
            onChange={observeBody.bind(null, "password")}
            validations={[required]}
          />
          <FormFeedback />
        </FormGroup>
        <Typography className={not_found_user_message}>{error}</Typography>
        <Button type="submit" className={auth_button}>{intl.formatMessage({ id: "login" })}</Button>
      </Form>
      <Box className={footer__item}>
        {/*<Typography>*/}
        {/*  {intl.formatMessage({ id: "forgotpassword" })} <Link to="/users/login">{intl.formatMessage({ id: "recover" })}</Link>*/}
        {/*</Typography>*/}
        <Typography> {intl.formatMessage({ id: "or" })} </Typography>
      </Box>
      <Box className={footer__item}>
        <Typography>
          {intl.formatMessage({ id: "loginwith" })}
        </Typography>
        <SocialAuth />
      </Box>
      <Box className={footer__item}>
        <Typography>
          {intl.formatMessage({ id: "noaccount" })} <Link to="/users/signup">{intl.formatMessage({ id: "signup" })}</Link>
        </Typography>
      </Box>
    </Layout>
  )
}

export default Login;
